.oc-sticky {
    $this: &;
    
    position: sticky;

    &__top {
        
        &--0 {
            top: 0;
        }
    }

    &__right {
        
        &--0 {
            right: 0;
        }
    }

    &__bottom {
        
        &--0 {
            bottom: 0;
        }
    }

    &__left {
        
        &--0 {
            left: 0;
        }
    }

}