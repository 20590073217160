// Reset de estilos

html {
  scroll-behavior: smooth;
}

// Reiniciar márgenes y relleno en todos los elementos
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// Reiniciar estilos de lista
ul,
ol {
  list-style: none;
}

// Reiniciar estilos de enlaces
a {
  text-decoration: none;
  color: inherit;
}

// Reiniciar estilos de encabezados
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: normal;
}

// Reiniciar estilos de párrafos
p {
  margin: 0;
}

// Reiniciar estilos de formularios
input,
textarea,
button {
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  outline: none;
}

// Reiniciar estilos de imágenes
img {
  max-width: 100%;
  height: auto;
}

