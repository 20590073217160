.cc-tooltip {
    display: flex;
    align-items: center;

    &__content {
        z-index: 9000;
        position: absolute;
        background-color: $co-lighter;
        font-size: $display-9;
    }

    &--info {
        cursor: help;
    }
}