.cc-main {
    $this: &;

    // position: relative;
    // top: 50px;
    display: flex;
    flex-direction: column;
    // gap: 1.25rem;
    width: 100vw;
    // height: 100vh;
    height: calc(100vh - 50px);
    padding: $gutter;
    background: $co-grey-6;
    overflow: auto;

    &__wrapper {
        position: relative;
        // top: 50px;
    }

}
