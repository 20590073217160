@mixin load-font($name, $file, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;
    $path: "#{$fontsUrl}/#{$file}";
    $extmods: (
        eot: "?",
        svg: "#" + str-replace($name, " ", "_"),
    );
    $formats: (
        otf: "opentype",
        ttf: "truetype",
    );
    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }
    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-display: swap;
        font-weight: $weight;
        src: $src;
    }
}

@mixin shadow {
    box-shadow: 0px 0px 10px 0px $co-shadow-1;
}

@mixin border-radius {
    border-radius: 4px;
}

@mixin text-shadow-white {
    text-shadow: 0px 0px 2px $co-lighter;
}

@mixin text-shadow-black {
    text-shadow: 0px 0px 2px $co-shadow-1;
}

@mixin loading-anim {
    div {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        transform: translate(40px, 40px) scale(1);
        background: $co-primary;
        animation: is-loading__anim 1.1764705882352942s infinite cubic-bezier(0, 0.5, 0.5, 1);
        box-sizing: content-box;
        &:nth-child(1) {
            background: $co-primary;
            transform: translate(74px, 40px) scale(1);
            animation: is-loading__anim-r 0.29411764705882354s infinite cubic-bezier(0, 0.5, 0.5, 1),
                is-loading__anim-c 1.1764705882352942s infinite step-start;
        }
        &:nth-child(2) {
            animation-delay: -0.29411764705882354s;
            background: $co-primary-dark;
        }
        &:nth-child(3) {
            animation-delay: -0.5882352941176471s;
            background: $co-primary;
        }
        &:nth-child(4) {
            animation-delay: -0.8823529411764706s;
            background: $co-primary-light;
        }
        &:nth-child(5) {
            animation-delay: -1.1764705882352942s;
            background: $co-primary;
        }
    }

    @keyframes is-loading__anim {
        0% {
            transform: translate(6px, 40px) scale(0);
        }
        25% {
            transform: translate(6px, 40px) scale(0);
        }
        50% {
            transform: translate(6px, 40px) scale(1);
        }
        75% {
            transform: translate(40px, 40px) scale(1);
        }
        100% {
            transform: translate(74px, 40px) scale(1);
        }
    }
    @keyframes is-loading__anim-r {
        0% {
            transform: translate(74px, 40px) scale(1);
        }
        100% {
            transform: translate(74px, 40px) scale(0);
        }
    }
    @keyframes is-loading__anim-c {
        0% {
            background: $co-primary;
        }
        25% {
            background: $co-primary;
        }
        50% {
            background: $co-primary;
        }
        75% {
            background: $co-primary;
        }
        100% {
            background: $co-primary;
        }
    }
}

@mixin breakpoint($breakpoint) {
    @if $breakpoint == desktop {
        @media only screen and (max-width: 1300px) {
            @content;
        }
    }

    @if $breakpoint == laptop {
        @media only screen and (max-width: 991px) {
            @content;
        }
    }

    @if $breakpoint == tablet {
        @media only screen and (max-width: 680px) {
            @content;
        }
    }

    @if $breakpoint == mobile {
        @media only screen and (max-width: 481px) {
            @content;
        }
    }
}