// Settings Comitium

$fontsUrl: "../../assets/fonts";

body {
    overflow: hidden;
    font-family: "Open Sans", sans-serif;
    margin: 0;
}

// Fonts
$ff-primary: "Open Sans", sans-serif;

// Colors
$co-primary: #A3012D;
$co-secondary: #3BB0BF;
$co-tertiary: #8cabb5;
$co-primary-dark: #7c0424;
$co-primary-light: #f5d1da;

$co-lighter: white;
$co-darker: black;

$co-grey-1: #454545;
$co-grey-2: #888888;
$co-grey-3: #bbbbbb;
$co-grey-4: #dddddd;
$co-grey-5: #eeeeee;
$co-grey-6: #f5f5f5;
$co-grey-7: #f7f7f7;
$co-grey-8: #fafafa;

// Charts
// $co-chart-direct: #A5002D;
// $co-chart-seo: #00A3B9;
// $co-chart-social: #7A1F95;
// $co-chart-internal: #DEC500;
// $co-chart-other: #97A300;
// $co-chart-last: $co-grey-2;

$co-chart-direct: #D56073;
$co-chart-seo: #F7CA44;
$co-chart-social: #7A4579;
$co-chart-internal: #4D7CAE;
$co-chart-dark-social: #D18E10;
$co-chart-other: #7DA87B;
$co-chart-last: $co-grey-2;
$co-chart-rest: #c98698;



// Shadows
$co-shadow-1: #88888850;

// Borders
$co-border: #dddddd;

// Transparent
$co-background-transparent-dark: #00000080;
$co-background-transparent-light: #ffffffd6;

//Status
$co-status-positive: #008B05;
$co-status-negative: #D50000;

// Alerts
$co-danger-light: #c85441;
$co-danger: #b94330;
$co-danger-dark: #C00;
$co-alert:#e49a03;
$co-alert-light:#eadcb6;
$co-success: #7aac3c;
$co-error: $co-danger;
$co-warning: $co-alert;
$co-info: $co-primary;

// Routes
$imagesUrl: "../../assets";

// Sizes
$gutter: 1.25rem;

// Scrollbar
$scrollbar-size: 8px;

// Font displays:
$display-0: 96px;
$display-1: 34px;
$display-2: 24px;
$display-3: 20px;
$display-4: 18px;
$display-5: 16px;
$display-6: 14px;
$display-7: 13px;
$display-8: 12px;
$display-9: 11.5px;
$display-10: 10px;
$display-11: 9.5px;
$display-12: 8px;

// Sidebar
$sidebar-width: 300px;