.material-symbols-outlined,
.mc-icon {
    font-family: "Material Symbols Outlined";
    font-weight: 200;
    font-style: normal;
    font-size: $display-2;
    display: inline-block;
    font-display: block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    -webkit-text-stroke: 0.45px rgba(0, 0, 0, 0.1);

    &--section {
        svg {
            padding: 0.16rem 0;
            opacity: 0.6;
        }
    }

    &--size {
        &-10 {
            font-size: $display-10;
            font-optical-sizing: none;
        }
        &-14 {
            font-size: $display-6;
            font-optical-sizing: none;
        }
        &-16 {
            font-size: $display-5;
            font-optical-sizing: none;
        }
        &-18 {
            font-size: $display-4;
            font-optical-sizing: none;
        }
        &-20 {
            font-size: $display-3;
            font-optical-sizing: none;
        }
        &-24 {
            font-size: $display-2;
            font-optical-sizing: none;
        }
    }

    &--button {
        cursor: pointer;
        pointer-events: all;
        &:hover {
            color: $co-primary;
            fill: $co-primary;
        }
    }

    &--draggable {
        cursor: all-scroll;
        &:hover {
            color: $co-primary;
        }
    }

    &--info {
        cursor: text;
    }

    &--mobile-cam {
        opacity: 0.1;
    }

    &--mobile-icons {
        display: flex;
        gap: 0.5rem;
        font-size: $display-10;
        -webkit-font-smoothing: initial;
    }

    &--lock {
        position: absolute;
        z-index: 400;
        padding: 0.1rem;
        border-radius: 50%;
        background-color: $co-lighter;
        border: solid 1px $co-grey-2;
        font-size: $display-5;
        font-weight: 400;
        color: $co-grey-2;

        &:hover {
            background: $co-primary;
            color: $co-lighter;
            border-color: $co-primary;
        }
    }

    &--locked-version {
        position: absolute;
        right: 0;
        z-index: 200;
    }

    &--filled {
        display: flex !important;
        color: $co-primary;
        font-variation-settings: "FILL" 1, "wght" 200, "GRAD" -25, "opsz" 40;

        #vertical-tabpanel-0 & {
            display: none !important;
        }

        .MuiListItemIcon-root:hover & {
            #vertical-tabpanel-0 & {
                display: flex !important;
            }
        }
    }
    &--on-filled {
        @include text-shadow-white;
        &:hover {
            color: $co-primary-dark;
            font-variation-settings: "FILL" 1, "wght" 400, "GRAD" -25, "opsz" 40;
        }
    }
    &--danger {
        color: $co-danger-light;
        width: fit-content;
    }

    &--add {
        display: flex;
        justify-content: center;
        padding: 0;
        transition: all 0.2s ease-in-out;
        color: $co-grey-2;
        cursor: pointer;

        width: 100%;
        max-width: 24px;
        max-height: 24px;

        border-radius: 50%;
        border: solid 1px $co-grey-2;
        margin: 0 auto;
        align-items: center;
        background: $co-lighter;

        font-weight: 200;

        &:hover {
            color: $co-darker;
        }

        &[aria-label="create-section"] {
            .is-content & {
                display: none;
            }
        }

    }

    &--close {
        margin-bottom: 1rem;
        margin-top: -1rem;
        margin-right: -0.25rem;
    }

    &--linked {
        position: relative;
        left: -3px;
        font-weight: 400;
        line-height: 0.75;
        background: $co-danger;

        .is-editing & {
            pointer-events: none;
        }
    }
    &--danger {
        color: $co-danger
    }
    &--warning {
        color: $co-alert;
    }

    &--transparent {
        opacity: .5;
    }

    &__entity {

        &--101:before {
            content: "note"
        }

        &--102:before {
            content: "notes"
        }

        &--103:before {
            content: "calendar_today"
        }

        &--104:before {
            content: "bar_chart"
        }

        &--107:before {
            content: "list_alt"
        }

        &--108:before {
            content: "push_pin"
        }

        &--109:before {
            content: "photo"
        }

        &--110:before {
            content: "play_circle"
        }

        &--111:before {
            content: "volume_up"
        }

        &--112:before {
            content: "description"
        }

        &--113:before {
            content: "photo_library"
        }

        &--115:before {
            content: "person"
        }

        &--116:before {
            content: "extension"
        }

        &--117:before {
            content: "list"
        }

        &--118:before {
            content: "category"
        }

        &--119:before {
            content: "sell"
        }

        &--120:before {
            content: "calendar_month"
        }

        &--126:before {
            content: "event_available"
        }

        &--127:before {
            content: "casino"
        }
    }

    &__generic {
        
        flex-shrink: 0;
        fill: $co-grey-1;

        .is-active & {
            fill: $co-primary ;
        }

        &.is-white {
            fill: $co-lighter;
        }

        &.is-primary {
            fill: $co-primary;
        }

        &.is-success {
            fill: $co-success;
        }

        &--size {
            &-24 {
                width: 24px;
                height: 24px;
            }
            &-20 {
                width: 20px;
                height: 20px;
            }
            &-18 {
                width: 18px;
                height: 18px;
            }
            &-16 {
                width: 16px;
                height: 16px;
            }
            &-14 {
                width: 14px;
                height: 14px;
            }
            &-12 {
                width: 12px;
                height: 12px;
            }
            &-10 {
                width: 10px;
                height: 10px;
            }
            &-9 {
                width: 9px;
                height: 9px;
            }
            &-7 {
                width: 7px;
                height: 7px;
            }
        }
    }
}