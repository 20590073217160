// Material Symbols
@font-face {
    font-family: "Material Symbols Outlined";
    font-style: normal;
    src: url(#{$fontsUrl}/MaterialSymbolsOutlined.woff2) format("woff2");
}
// Open Sans
@include load-font('Open Sans', 'OpenSans-Regular', 400, $exts: woff2);
@include load-font('Open Sans', 'OpenSans-Semibold', 500, $exts: woff2);
@include load-font('Open Sans', 'OpenSans-Bold', 600, $exts: woff2);
@include load-font('Open Sans', 'OpenSans-ExtraBold', 700, $exts: woff2);