.cc-sidebar {
    $this: &;

    position: relative;
    width: $sidebar-width;
    min-width: $sidebar-width;
    height: calc(100vh - 50px);
    background-color: $co-lighter;
    z-index: 6100;
    overflow: auto;
    transition: all .25s ease-in-out;
    @include shadow;

    @include breakpoint(tablet) {
        position: absolute;
        right: 0;
    }

    &.is-hidden {
        width: 0;
        min-width: 0;
    }

    &__collapse {
        position: fixed;
        top: 70px;
        right: calc($sidebar-width - 10px);
        z-index: 6200;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $co-grey-6;
        background: linear-gradient(270deg, rgb(255 255 255 / 0%) 0%, $co-grey-6 30%);
        border-radius: 50%;
        box-shadow: inset -4px 0px 2px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        transition: all .25s ease-in-out;

        &.is-hidden {
            right: 0;
            transform: rotate(180deg);
            box-shadow: none;
        }

        &--button {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: $co-lighter;
            background: $co-grey-3;
            font-size: $display-10;
            cursor: pointer;

            &:hover {
                background-color: $co-grey-2;
            }

            .mc-icon__generic {
                fill: $co-lighter;
            }
        }
    }
}
