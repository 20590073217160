.cc-chart {
    $this: &;

    &__main {
        position: relative;
        z-index: 2000;
        width: 100%;
        height: 220px;
        margin-top: calc($gutter / 2);
        margin-bottom: $gutter;

        .cc-card--detail & {
            margin-bottom: 0;
            padding-bottom: $gutter;
            border-bottom: solid 1px $co-border;
        }

        &--area-last {
            stroke: $co-grey-2;
            .recharts-layer path {
                &:first-child {
                    fill: $co-grey-3;
                    fill-opacity: 0;
                }
                &:last-child {
                    fill: none;
                }
            }
        }
        &--area-direct {
            stroke-width: 1px;
            stroke: $co-chart-direct;
            .recharts-layer path {
                &:first-child {
                    fill: $co-chart-direct;
                    fill-opacity: 1;
                }
                &:last-child {
                    fill: none;
                }
            }
        }
        &--area-social {
            stroke-width: 1px;
            stroke: $co-chart-social;
            .recharts-layer path {
                &:first-child {
                    fill: $co-chart-social;
                    fill-opacity: 1;
                }
                &:last-child {
                    fill: none;
                }
            }
        }
        &--area-dark-social {
            stroke-width: 1px;
            stroke: $co-chart-dark-social;
            .recharts-layer path {
                &:first-child {
                    fill: $co-chart-dark-social;
                    fill-opacity: 1;
                }
                &:last-child {
                    fill: none;
                }
            }
        }
        &--area-seo {
            stroke-width: 1px;
            stroke: $co-chart-seo;
            .recharts-layer path {
                &:first-child {
                    fill: $co-chart-seo;
                    fill-opacity: 1;
                }
                &:last-child {
                    fill: none;
                }
            }
        }
        &--area-internal {
            stroke-width: 1px;
            stroke: $co-chart-internal;
            .recharts-layer path {
                &:first-child {
                    fill: $co-chart-internal;
                    fill-opacity: 1;
                }
                &:last-child {
                    fill: none;
                }
            }
        }
        &--area-other {
            stroke-width: 1px;
            stroke: $co-chart-other;
            .recharts-layer path {
                &:first-child {
                    fill: $co-chart-other;
                    fill-opacity: 1;
                }
                &:last-child {
                    fill: none;
                }
            }
        }

        .recharts-responsive-container {
            @include border-radius;

            .recharts-wrapper {
                top: 10px;
                left: -10px;
            }
        }

        .recharts-cartesian-axis-ticks {
            font-size: $display-10;
        }
    }

    &-bar {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding: 1rem 0.5rem 1rem 1rem;
        border-bottom: solid 1px $co-border;

        &__wrapper {
            width: 100%;
            align-items: center;

            .cc-tooltip {
                max-width: 200px;
            }
        }

        &__title {
            width: max-content;
            margin-bottom: 0.25rem;
            font-size: $display-9;
            font-weight: 600;
            text-transform: uppercase;
            color: $co-grey-1;
        }

        &__item {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: .5rem;
            width: 100%;
            padding: 1px 0.25rem 1px 0;
            box-sizing: border-box;
            transition: all 0.25s ease-in-out;
            cursor: pointer;

            #{$this}-bar__source &,
            #{$this}-bar__country & {
                &:nth-child(even) {
                    background-color: $co-grey-8;
                }
            }

            &:hover {
                background-color: $co-grey-8;
                // font-weight: 600;
            }

            .cc-content-list & {
                padding: 0;
                background-color: $co-grey-6;
            }

            &--wrapper {
                position: absolute;
                left: 70px;
                width: 60%;
                background-color: $co-grey-5;
                @include border-radius;

                .is-active & {
                    background: $co-grey-4;
                }
            }

            &::before {
                display: flex;
                align-items: center;
                width: 10px;
                min-width: 10px;
                height: 10px;
                content: "";
                background-image: url(#{$imagesUrl}/filters-small-ico.svg);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                opacity: .75;
            }

            &:hover:not(.is-active, .cc-content-list__data--chart &) {
                &::before {
                    background-image: url(#{$imagesUrl}/filters-filled-primary-ico.svg);
                }
            }

            &.is-active {
                &::before {
                    background-image: url(#{$imagesUrl}/filters-filled-primary-ico.svg);
                }
            }

            &-name {
                z-index: 10;
                height: 100%;
                font-size: $display-9;
                font-weight: 400;
                color: $co-grey-1;

                display: block;
                width: 70px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                [data-type="direct"] & {
                    color: $co-chart-direct;
                }
                [data-type="seo"] & {
                    color: $co-chart-seo;
                }
                [data-type="social"] & {
                    color: $co-chart-social;
                }
                [data-type="dark_social"] & {
                    color: $co-chart-dark-social;
                }
                [data-type="internal"] & {
                    color: $co-chart-internal;
                }
                [data-type="other"] & {
                    color: $co-chart-other;
                }

                // .is-active & {
                //     font-weight: 600;
                // }

                .cc-chart-bar__source &,
                .cc-chart-bar__campaign &,
                .cc-chart-bar__country & {
                    width: auto;
                    // max-width: 220px;
                }
            }

            &-graph {
                display: flex;
                align-items: center;
                height: 6px;
                @include border-radius;
                background-color: $co-chart-rest;
                transition: width 0.5s ease-in-out;
                opacity: 0.8;

                [data-type="direct"] & {
                    background-color: $co-chart-direct;
                }
                [data-type="seo"] & {
                    background-color: $co-chart-seo;
                }
                [data-type="social"] & {
                    background-color: $co-chart-social;
                }
                [data-type="internal"] & {
                    background-color: $co-chart-internal;
                }
                [data-type="other"] & {
                    background-color: $co-chart-other;
                }
            }

            &-value {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                z-index: 10;
                min-width: 30px;
                height: 100%;
                font-size: $display-9;
                font-weight: 500;
                text-transform: uppercase;
                color: $co-grey-2;

                // .is-active & {
                //     padding-left: 2px;
                //     background: $co-grey-6;
                //     font-weight: 600;

                //     // &::before {
                //     //     position: absolute;
                //     //     left: -14px;
                //     //     display: inline-flex;
                //     //     width: 6px;
                //     //     height: 6px;
                //     //     padding-left: 1rem;
                //     //     content: "";
                //     //     background-image: url(#{$imagesUrl}/close-ico.svg);
                //     //     background-repeat: no-repeat;
                //     //     background-size: 12px;
                //     //     background-position: center;
                //     // }
                // }
            }

            &.is-active {
                background-color: $co-grey-6;
            }
        }
    }

    &__tooltip {
        position: relative;
        background: $co-lighter;
        z-index: 999999;
        @include border-radius;
        @include shadow;
        pointer-events: none;

        &-date {
            padding: 6px 10px;
            border-bottom: solid 1px $co-grey-4;
            color: $co-grey-1;
            font-size: $display-8;
            background-color: $co-grey-5;
        }
        &-vs {
            border: none;
            padding: 0 calc($gutter / 3);
            color: $co-grey-2;
            font-size: $display-8;
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            gap: 2px;

            &:last-child {
                padding: 3px 0 0;
                border-top: solid 1px $co-grey-3;
                border-bottom: none;
                background-color: $co-grey-6;
                #{$this}__tooltip-item-name {
                    color: $co-darker;
                    font-size: $display-6;
                    font-weight: 600;
                    text-transform: uppercase;
                }

                #{$this}__tooltip-item-bar--new,
                #{$this}__tooltip-item-bar--old {
                    display: none;
                }

                #{$this}__tooltip-item-value--new {
                    font-size: $display-5;
                    font-weight: 600;
                }

                #{$this}__tooltip-item-average {
                    font-size: $display-7;
                }
            }
        }

        &-item {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            gap: 2px calc($gutter / 2);
            border-bottom: solid 1px $co-grey-5;
            font-size: $display-7;
            max-height: 34px;
            padding: 4px 0;

            &-name {
                min-width: 85px;
                padding-left: calc($gutter / 2);
                color: $co-darker;
                font-size: $display-9;
            }

            &-bar {
                display: flex;
                flex-direction: column;
                gap: 1px;
                width: 100%;
                min-width: 100px;
                max-width: 100px;
                background: $co-grey-6;

                &--new,
                &--old {
                    transition: width 0.15s linear;
                }

                &--new {
                    height: 6px;
                    border-radius: 2px;
                }

                &--old {
                    height: 3px;
                    border-radius: 2px;
                    opacity: 0.4;
                }
            }

            &-value {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 60px;
                _height: 38px;
                padding-right: $gutter;
                text-align: right;

                &-wrapper {
                    display: flex;
                    align-items: center;
                }

                &--new {
                    color: $co-darker;
                    font-size: $display-6;
                    font-weight: 400;
                    line-height: 1;
                }

                &--old {
                    color: $co-grey-1;
                    font-size: $display-11;
                    font-weight: 300;
                }
            }

            &-average {
                _width: 100%;
                _max-width: 30px;
                min-width: 50px;
                height: -webkit-fill-available;
                margin-left: auto;
                // padding-right: $gutter;
                font-size: $display-9;
                text-align: left;

                .is-zero {
                    color: $co-grey-2;
                }

                .is-negative {
                    color: $co-status-negative;
                }

                .is-positive {
                    color: $co-status-positive;
                }
            }
        }
    }
}
