.cc-ai {
    $this: &;

    &__selector {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        min-width: 16px;
        height: 16px;
        border: solid 1px $co-grey-3;
        border-radius: 50%;
        cursor: pointer;
    }

    &__suggest {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 60%;
        padding: $gutter;
        border-left: solid 1px $co-border;
        background: $co-grey-6;

        @include breakpoint(tablet) {
            width: 100%;
            border: none;
        }
    }

    &__title {
        color: $co-primary;
        font-size: $display-4;
        font-weight: 600;
    }

    &__subtitle {
        margin-bottom: calc($gutter / 2);
        color: $co-grey-2;
        font-size: $display-8;
    }

    &__source-title {
        margin: 12px 0 4px 0;
        color: $co-primary;
        font-size: $display-6;
        font-weight: 600;
    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: calc($gutter / 3);
        margin-bottom: calc($gutter / 2);

        &-title {
            margin-top: calc($gutter / 2);
            color: $co-grey-1;
            font-size: $display-9;
            font-weight: 600;
            text-transform: uppercase;
        }

        &-list {
            display: flex;
            flex-direction: column;
            gap: calc($gutter / 4);
        }

        &-proposal {
            position: relative;
            display: flex;
            align-items: center;
            gap: calc($gutter / 2);
            width: 100%;
            padding: calc($gutter / 3);
            border: solid 1px $co-border;
            border-radius: 4px;
            background: $co-lighter;
            color: $co-grey-2;
            font-size: $display-7;
            cursor: text;

            &.is-selected {
                border-color: $co-success;
            }

            &--content {

                border-left: solid 1px #dbd8d8;
                padding-left: calc($gutter / 2);

                &[contentEditable="true"]:focus {
                    outline: 0;
                }
            }

            &--selected {
                position: relative;
                display: flex;
                width: 100%;
                padding-right: 12px;
                color: $co-primary;
                font-weight: 600;

                &::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 10px;
                    height: auto;
                    margin: auto;
                    content: "";
                    background-image: url(#{$imagesUrl}/check-green-ico.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            &[contentEditable="true"]:focus {
                outline: 0;
                border-color: $co-primary;
            }
            
        }
    }
}