.cc-content {
    $this: &;

    width: 100%;
    margin-bottom: calc($gutter * 2);

    .cc-main--typology & {
        margin-bottom: 0;
    }

    &-header {
        display: flex;
        flex-direction: column;
        flex: 1 0 100%;
        gap: 1rem;
        border-bottom: solid 1px $co-border;

        &__title {
            font-size: 12px;
            color: $co-grey-2;
            font-weight: 500;
        }

        .oc-group {
            align-items: center;
            padding-bottom: calc($gutter / 2);
        }
    }

    &-nav {
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: column;
        flex: 0 1 20%;
        gap: $gutter;
        width: 100%;
        max-width: 175px;
        height: fit-content;

        @include breakpoint(tablet) {
            top: -20px;
            flex: auto;
            flex-direction: row;
            flex-wrap: wrap;
            gap: $gutter;
            max-width: unset;
            padding-bottom: calc($gutter / 2);
            border-bottom: solid 1px $co-border;
            overflow: auto;
            background: $co-lighter;
            z-index: 3000;
        }

        @include breakpoint(mobile) {
            top: 0;
        }

        &__list {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 0.5rem;

            @include breakpoint(tablet) {
                flex-direction: row;
                align-self: flex-start;
                flex: 1 0 50%;
                // flex-wrap: nowrap;

                .mc-button--rounded {
                    width: max-content;
                    padding: 0 8px;
                    font-size: $display-9;
                }

                .cc-chart-bar__title {
                    flex-basis: 100%;
                    margin: 0;
                }
            }

            &--subitems {
                @include breakpoint(tablet) {
                    flex: 1 0;
                }
                .mc-button:not(:first-of-type) {
                    min-width: 135px;
                    margin-left: 10px;
                    padding: 2px 10px;
                    font-size: $display-9;

                    @include breakpoint(laptop) {
                        min-width: 100px;
                        max-width: 100px;
                        width: fit-content;
                    }

                    @include breakpoint(tablet) {
                        min-width: unset;
                        margin: 0;
                        padding: 0 8px;
                    }

                    &:before {
                        content: "";
                        width: 8px;
                        height: 8px;
                        background-image: url(#{$imagesUrl}/arrow-down-ico.svg);
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        transform: rotate(-90deg);
                    }

                    &.is-active {
                        &:before {
                            background-image: url(#{$imagesUrl}/arrow-down-primary-ico.svg);
                        }
                    }
                }
            }
        }

        &__items {
            display: flex;
            flex-direction: column;
            gap: 6px;

            @include breakpoint(tablet) {
                flex-direction: row;
            }
        }
    }

    &-list {
        display: flex;
        flex-direction: column;
        flex: 1 0;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        // &__referer {

        //     font-size: $display-7;
        //     // cursor: pointer;

        //     // .cc-tooltip__content {
        //     //     width: fit-content;
        //     //     max-width: 580px;
        //     //     margin-top: $gutter;
        //     //     margin-left: $gutter;
        //     //     padding: $gutter/2;
        //     //     border: solid 1px $co-border;
        //     //     border-radius: 4px;
        //     //     color: $co-grey-2;
        //     // }

        //     // .mc-icon__generic {
        //     //     opacity: .5;
        //     //     fill: $co-primary;

        //     //     &:hover {
        //     //         opacity: 1;
        //     //     }
        //     // }
        // }

        &__no-results {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: calc($gutter / 3);
            width: 100%;
            height: 100%;
            padding-bottom: $gutter;
            border-radius: 4px;
            color: $co-grey-1;

            &--omg {
                margin-bottom: calc($gutter / 2);
                font-size: 4rem;
                color: $co-grey-5;
            }
            &--title {
                color: $co-grey-1;
                font-size: $display-5;
            }
            &--subtitle {
                color: $co-grey-2;
                font-size: $display-9;
            }
        }

        &__item {
            container: item / inline-size;
            display: flex;
            flex-wrap: wrap;
            gap: $gutter;
            width: 100%;
            padding: 1rem 0;
            border-bottom: solid 1px $co-border;
            transition: all 0.25s ease-in-out;
            // padding-right: $gutter;
            cursor: pointer;

            // &:hover {
            //     background: $co-grey-8;

            //     .mc-icon__generic {
            //         fill: $co-primary;
            //         stroke-width: 0.25px;
            //         stroke: $co-primary;
            //     }

            //     .cc-content-list__title--filter::before {
            //         background-image: url(#{$imagesUrl}/filters-filled-primary-ico.svg);
            //     }
            // }

            // &:first-child {
            //     padding-top: 0;
            // }
        }

        &__picture {
            position: relative;
            display: flex;
            width: 75px;
            height: auto;
            overflow: hidden;
            aspect-ratio: 1/1;
            @include border-radius;

            &--image {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }

            #{$this}__item & {
                flex: 0 0 auto;
                width: 220px;
                height: fit-content;

                @include breakpoint(tablet) {
                    max-width: 100px;
                }

                img {
                    object-fit: cover;
                    object-position: center;
                    width: 100%;
                    height: 100%;
                }
            }

            @container (max-width: 400px) {
                // order: 1;
                max-width: 60px;
                height: fit-content;
                aspect-ratio: 1/1;
            }
        }

        &__article {
            display: flex;
            flex-direction: column;
            // flex: 0 0 40%;
            flex: 1;
            gap: 0.5rem;
            overflow: auto;
            overflow-wrap: break-word;

            &--404 {
                flex: 1 0 !important;
                flex-basis: calc(40% - 90px) !important;

                @container (max-width: 470px) {
                    flex-basis: 100% !important;
                }
            }

            @container (max-width: 600px) {
                // flex-basis: calc(100% - 90px);
                flex: 1;
            }

            // @container (max-width: 400px) {
            //     flex-basis: 100%;
            // }

            #{$this}__item & {
                flex: 1 1 auto;
            }
        }

        &__title {
            color: $co-grey-1;
            font-size: $display-5;
            font-weight: 500;
            letter-spacing: -0.4px;

            #{$this}__item & {
                font-size: $display-2;

                @include breakpoint(tablet) {
                    font-size: $display-4;
                }
            }

            &--filter {
                display: flex;
                align-items: center;
                gap: calc($gutter / 2);

                &::before {
                    display: flex;
                    align-items: center;
                    width: 10px;
                    height: 10px;
                    content: "";
                    background-image: url(#{$imagesUrl}/filters-ico.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    opacity: 0.75;
                }
            }

            &--404 {
                color: $co-grey-2;
                font-size: $display-8;
                font-weight: 200;
                transition: all 2s ease-in-out;

                &-label {
                    color: $co-primary;
                    font-weight: 500;
                }

                &-main-link:hover {
                    text-decoration: underline;

                    svg {
                        fill: $co-primary;
                    }
                }

                &-link:hover {
                    text-decoration: underline;

                    svg {
                        fill: $co-primary;
                    }
                }
            }

            svg {
                margin-left: 6px;
                margin-bottom: -3px;

                &.mc-icon--filter {
                    margin: 0;
                    margin-right: 0.5rem;
                }
            }
        }

        &__info {
            display: flex;
            align-items: center;
            gap: 0.65rem;
            color: $co-grey-1;
            font-size: $display-9;

            #{$this}__item & {
                font-size: $display-9;
            }

            > * {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 3px;
                border-right: solid 1px $co-border;
                padding-right: 10px;
                box-sizing: content-box;

                &:first-child,
                &:last-child {
                    padding-right: 0;
                    border: none;
                }

                svg {
                    fill: $co-grey-2;
                }
            }

            // * {
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
            //     gap: 3px;

            //     svg {
            //         fill: $co-grey-2;
            //     }
            // }

            &--filters {
                position: absolute;
                left: 1rem;
                padding: 6px 8px;
                gap: 8px;
                background: $co-lighter;
                border: solid 1px $co-border;
                @include border-radius;
                @include shadow;
            }

            &--main {
                flex-wrap: wrap;
                gap: 0.5rem 1rem;
                padding-top: 1rem;
                border-top: solid 1px $co-border;
            }
        }

        &__data {
            display: flex;
            flex: 1;
            gap: 2%;
            justify-content: space-between;
            flex: 0 1 30%;
            margin-left: auto;

            > * {
                width: 100%;
                align-items: center;

                &:last-child {
                    align-items: center;
                }
            }

            @container (max-width: 600px) {
                flex-basis: 100%;
                justify-content: flex-start;
                gap: $gutter * 2;
                max-width: unset;
                // margin-left: 0;
            }

            @container (max-width: 481px) {
                // order: 2;
                // flex-basis: calc(100% - 90px);
                // flex-wrap: wrap;
                // gap: calc($gutter / 2) 0;
                // padding-top: calc($gutter / 2);
                // border-top: solid 1px $co-border;
                // justify-content: space-between;
                gap: $gutter;
            }

            &--label {
                color: $co-grey-2;
                font-size: $display-10;

                #{$this}-list__data & {
                    display: none;

                    .is-skeleton & {
                        display: block;
                        color: $co-lighter;
                    }

                    @include breakpoint(laptop) {
                        display: block;
                    }
                }
            }

            &--value {
                color: $co-grey-1;
                font-size: $display-5;

                &-main {
                    color: $co-secondary;
                }
            }

            &--chart {
                display: flex;
                flex: 0 0 20%;
                max-width: 100px;

                @container (max-width: 400px) {
                    flex-basis: 10%;
                    flex-grow: 1;
                }

                .cc-chart-bar {
                    border: none;
                }

                .cc-chart-bar__channel {
                    width: 100%;
                    gap: calc($gutter / 3);
                    padding: 0;
                }
                .cc-chart-bar__item {
                    pointer-events: none;
                }

                .cc-chart-bar__item-graph {
                    height: 4px;
                }
            }

            &--pages {
                display: flex;
                flex-direction: column;
                gap: 0.1rem;

                // @container (max-width: 400px) {
                //     flex-grow: 1;
                // }
            }

            &--bounce {
                display: flex;
                flex-direction: column;
                gap: 0.1rem;
            }

            &--time {
                display: flex;
                flex-direction: column;
                gap: 0.1rem;

                // @container (max-width: 400px) {
                //     flex-basis: 70%;
                //     flex-grow: 1;
                //     padding-top: calc($gutter / 2);
                //     border-top: solid 1px $co-border;
                // }
            }

            &--concurrence {
                display: flex;
                flex-direction: column;
                gap: 0.1rem;

                // @container (max-width: 400px) {
                //     padding-top: calc($gutter / 2);
                //     border-top: solid 1px $co-border;
                // }
            }

            &--404 {
                width: calc(40% - 90px);
                max-width: 200px;
                min-width: 200px;

                @container (max-width: 480px) {
                    max-width: unset !important;
                    padding: 0;
                    justify-content: flex-start;
                    gap: 20%;
                    border: none;

                    .cc-content-list__data--concurrence {
                        padding: 0;
                        border: none;
                    }
                }
            }
        }

        &__ai {
            display: flex;
            align-items: center;
            gap: calc($gutter / 5);
            padding-top: calc($gutter / 2);
        }

        &__seo-label {
            color: $co-success;
            text-transform: uppercase;
            font-size: $display-9;
            font-weight: 600;
        }

        &__ai-link {
            display: flex;
            align-items: center;
            color: $co-primary;
            font-size: $display-8;
            font-weight: 600;
        }

        &__list {
            display: flex;
            gap: $gutter;

            @include breakpoint(laptop) {
                position: absolute;
                top: 26px;
                left: 0;
                flex-direction: column;
                gap: calc($gutter / 2);
                padding: $gutter;
                @include shadow;
                background-color: $co-lighter;
                opacity: 0;

                &.is-open {
                    opacity: 1;
                }
            }
        }

        &__sort {
            display: none;

            @include breakpoint(laptop) {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }

        &__sort-wrapper {
            position: sticky;
            top: calc($gutter * -1);
            align-items: center;
            justify-content: flex-end;
            gap: calc($gutter / 2);
            // margin-bottom: 1rem;
            padding-bottom: 6px;
            z-index: 100;
            background: $co-lighter;

            &:hover {
                background: $co-lighter;
            }

            &:before {
                content: "";
                position: absolute;
                bottom: 20px;
                width: 100%;
                height: 20px;
                background: linear-gradient(0deg, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 255, 0) 90%);
                // background: linear-gradient(transparent 70%, $co-lighter 90%);
            }

            @include breakpoint(laptop) {
                justify-content: flex-start;
            }

            .cc-content-list__list {
                justify-content: space-between;
                flex: 0 1 30%;
                gap: 2%;

                @include breakpoint(laptop) {
                    top: $gutter * 2;
                    gap: 10px;
                }

                > * {
                    width: 100%;
                    align-items: center;
                }

                &--404 {
                    width: calc(40% - 90px);
                    max-width: 200px;
                    min-width: 200px;
                }

                @include breakpoint(laptop) {
                    width: auto;
                }

                .cc-content-list__sort-button {
                    position: relative;
                    text-align: left;

                    .mc-icon__generic {
                        display: none;
                        position: absolute;
                        top: 1px;
                        left: -14px;
                    }
                    &.is-active {
                        .mc-icon__generic {
                            display: flex;
                        }
                    }
                    &:hover {
                        .mc-icon__generic {
                            display: flex;
                        }
                    }
                }
            }
        }

        &__sort-item {
            display: flex;
            flex-direction: column;
        }

        &__sort-button {
            display: flex;
            align-items: center;
            cursor: pointer;

            &.is-active {
                span {
                    // font-weight: 600;
                    // color: $co-primary !important;
                }
            }
        }
    }

    &-more {
        display: flex;
        justify-content: center;
        margin: $gutter 0;
    }

    &--ai {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    &__item {
        display: flex;
        // gap: $gutter;
        border-bottom: solid 1px $co-border;
        border-top: solid 1px $co-border;

        &:first-of-type {
            border-top: none;
        }

        @include breakpoint(mobile) {
            flex-wrap: wrap-reverse;
        }

        &--charts {
            display: flex;
            // flex-direction: column;
            gap: $gutter;
            flex: 1 0 auto;
            flex-direction: row;
            flex-wrap: wrap;
            width: 40%;
            padding: $gutter;

            div {
                display: flex;
            }

            .cc-chart-bar {
                flex: 1 0 auto;
                flex-wrap: wrap;
                min-width: 300px;

                @include breakpoint(laptop) {
                    min-width: 100%;
                }
            }

            @include breakpoint(tablet) {
                width: 100%;
                padding-right: $gutter;
            }

            .cc-chart-bar__item:hover:not(.is-active, .cc-content-list__data--chart &) {
                &::before {
                    left: -13px;
                }
            }

            .cc-chart-bar.cc-chart-bar__source {
                > * {
                    &:nth-child(n + 3):nth-child(odd) {
                        background-color: $co-grey-8;
                    }
                }
            }
        }

        .cc-content-list__article {
            padding: $gutter;
        }

        .cc-content-list__picture {
            padding: $gutter;

            @include breakpoint(tablet) {
                position: absolute;
                right: 0;
                margin: calc($gutter / 2);
                padding: 0;
            }

            @include breakpoint(mobile) {
                position: relative;
                right: unset;
                margin: 0;
                width: 100%;
                max-width: unset;
                max-height: 110px;
                aspect-ratio: 16 / 9;
            }
        }

        .cc-chart-bar {
            position: relative;
            padding: 0;
            border: none;
        }

        .cc-chart-bar__item {
            padding: 0;
        }

        .cc-chart-bar__item--wrapper {
            width: calc(100% - 110px);
        }

        .cc-chart-bar__title {
            font-size: $display-4;
            font-weight: 600;
            text-transform: math-auto;
        }

        .cc-chart-bar__item-name {
            font-size: $display-8;
        }

        .cc-tooltip__content {
            font-size: $display-8;
            font-weight: 600;
        }

        .cc-chart-bar__item-value {
            font-size: $display-8;
        }

        .cc-chart-bar__item-graph {
            height: 10px;
        }
    }

    &--tags {
        display: flex;
        flex-direction: column;
        gap: calc($gutter / 2);
        margin-top: calc($gutter / 2);
    }
}
