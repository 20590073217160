.cc-search {
    $this: &;

    &__button {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.5rem;
        min-height: 36px;
        padding: 8px 14px;
        background: $co-lighter;
        border: solid 1px $co-border;
        @include border-radius;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: -0.25px;
        cursor: pointer;
        transition: all 0.25s ease-in-out;

        &:hover {
            border-color: $co-primary;
            @include shadow;
        }

        &.is-mobile {
            display: none;
        }

        &.is-desktop {
            display: flex;
        }

        @include breakpoint(mobile) {
            &.is-mobile {
                display: flex;
            }
            &.is-desktop {
                display: none;
            }
        }
    }

    // position: relative;
    // padding: 0;
    // border-left: solid 1px $co-border;

    &__input-wrapper {
        &.is-desktop {
            position: absolute;
            left: 46px;
            z-index: 3000;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 36px;
            background: $co-lighter;

            @include breakpoint(tablet) {
                position: absolute;
                top: 50px;
                width: 100%;
                height: auto;
                padding: $gutter;
                @include shadow;
            }
        }
    }

    &__input {
        &.is-desktop {
            min-width: 260px;
            height: 100%;
            padding: 4px 10px;
            box-sizing: border-box;
            border: solid 1px $co-border;
            font-size: $display-8;

            &:focus {
                border-color: $co-primary;
                @include shadow;
            }

            @include breakpoint(tablet) {
                width: 100%;
                height: 30px;
            }
        }
    }

    &-suggest {
        position: absolute;
        top: 40px;
        display: flex;
        flex-direction: column;
        gap: 0;
        width: 260px;
        max-height: 80vh;
        overflow: auto;
        background: $co-lighter;
        @include shadow;

        @include breakpoint(tablet) {
            left: 0;
            right: 0;
            top: 50px;
            width: calc(100% - ($gutter * 2));
            margin: 0 auto;
        }

        &-item {
            padding: calc($gutter / 2);
            border-bottom: solid 1px $co-border;
            color: $co-grey-1;
            font-size: $display-9;

            &:hover {
                background: $co-primary-light;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}
