.mc-button {
    $this: &;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    width: max-content;
    min-width: 60px;
    height: 100%;
    margin: 0;
    padding: 5px 16px;
    font-size: $display-7;
    font-weight: 400;
    text-transform: none;
    line-height: 1.6;
    cursor: pointer;
    background-color: $co-lighter;
    border: solid 1px $co-border;
    border-radius: 0;
    color: $co-grey-1;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: $co-darker;
        text-decoration: none;
        border-color: $co-grey-2;

        .cc-content--tags & {
            color: $co-grey-1;
            border-color: $co-border;
            cursor: default;
        }
    }

    &.is-active {
        font-weight: 600;
        color: $co-primary;
        background-color: $co-grey-6;
        box-shadow: inset 4px 4px 4px -5px rgba(0, 0, 0, 0.2);
        border-bottom: solid 4px $co-primary;
        pointer-events: none;

        .mc-icon {
            margin-top: 1px;
        }

        .cc-content-nav__list & {
            pointer-events: all;
        }
    }

    &--primary,
    &--primary.MuiButton-root {
        background-color: $co-primary;
        border: solid 1px $co-primary;
        color: $co-lighter;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: $co-primary-dark;
            color: $co-lighter;
            text-decoration: none;
        }
    }

    &--secondary,
    &--secondary.MuiButton-root {
        background-color: transparent;
        &:hover {
            background-color: $co-grey-5 !important;
            border-color: $co-border !important;
        }
    }

    &--link {
        height: auto;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        // padding: .25rem 0 0 .25rem;
        padding: 0 0.5rem 1rem 1rem;
        border: 0;
        color: $co-primary;
        font-size: $display-11;
        text-decoration: underline;
        &:hover {
            opacity: 0.6;
            color: $co-primary;
            text-decoration: underline;
        }

        .cc-sidebar & {
            position: relative;
            // top: -1px;
            // border-bottom: solid 1px $co-border;
            padding-left: $gutter;
            background-color: $co-lighter;
            &:hover {
                opacity: 1;
                color: $co-primary;
                text-decoration: underline;
            }
        }

        .cc-content__item--charts & {
            padding-left: 0;
        }
    }

    &--header {
        width: -webkit-fill-available;
        justify-content: flex-start;
        background: $co-lighter;
    }

    &--no-border {
        border: 0;
    }

    &--rounded {
        position: relative;
        justify-content: flex-start;
        min-width: 145px;
        max-width: 145px;
        height: fit-content;
        border-radius: 22px;
        font-size: $display-7;

        @include breakpoint(laptop) {
            min-width: 110px;
            max-width: 110px;
            width: fit-content;
        }

        @include breakpoint(tablet) {
            min-width: unset;
            max-width: unset;
            width: fit-content;
        }

        @include breakpoint(mobile) {
            padding: 2px 12px;
        }

        &::after {
            display: none;
        }

        &.is-active {
            border: solid 1px $co-primary !important;
            color: $co-primary;
            box-shadow: none;
            background-color: $co-lighter;
        }
    }
    &--back {
        width: 28px;
        min-width: unset;
        height: 28px;
        justify-content: center;
        align-items: center;
        margin-right: $gutter;
        padding: 5px;
        background: none;
        border-color: $co-primary;
        color: $co-primary;

        &:hover {
            border-color: $co-primary;
            color: $co-primary;
            background: $co-lighter;
            @include shadow;
        }
    }

    &--delete-filters {
        min-width: unset;
        height: 36px;
        padding: 5px;
        @include border-radius;
        background: $co-lighter;
        font-size: $display-11;
        text-transform: uppercase;

        &:hover {
            border-color: $co-primary;
            color: $co-primary;
            @include shadow;
        }
    }

    &--start-ai {
        min-width: unset;
        margin-bottom: auto;
    }

    &--ai-apply {
        max-width: unset;
        margin-left: auto;
    }

    &--logout {
        border-left: solid 1px $co-border;
    }

    &.is-small {
        min-width: unset;
        max-width: unset;
        padding: 3px 10px;
        font-size: $display-10;
    }

    .is-filter {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.25s ease-in-out;

        .mc-icon__generic {
            opacity: 0;
        }
    }
    &:hover {
        .mc-icon__generic {
            opacity: 1;
        }
    }

    &.is-active {
        .mc-icon__generic {
            opacity: 1;
        }
    }

    &--toggle-filters {
        width: 0;
        min-width: unset;
        height: 28px;
        margin: 0;
        // margin-left: -10px;
        padding: 0;
        opacity: 0;
        visibility: hidden;
        border-color: $co-primary;
        border-radius: 50%;
        // @include border-radius;
        @include shadow;

        .is-overflow & {
            width: 28px;
            opacity: .5;
            visibility: visible;
            margin-right: $gutter;
            padding: 5px 8px;

            @include breakpoint(mobile) {
                margin-left: -10px;
                margin-right: 10px;
            }

            &:hover {
                opacity: 1;
                border-color: $co-primary;
            }
        }

        &.is-open {
            opacity: 1;
        }
    }
}
