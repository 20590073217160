.cc-card {
    $this: &;

    display: flex;
    flex-direction: column;
    min-width: 125px;
    min-height: 90px;
    padding: calc($gutter / 1.45);
    border: solid 1px $co-border;
    @include border-radius;
    background: $co-lighter;
    @include shadow;

    &--static {
        position: relative;
        bottom: 20vh;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $gutter;
        width: 50vh;
        color: $co-grey-1;
        text-align: center;

        @include breakpoint(mobile) {
            width: 90%;
        }
    }

    &--content {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
    }

    &__title {
        color: $co-grey-1;
        font-size: $display-11;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: -.25px;
    }
    &__subtitle {
        color: $co-grey-2;
        font-size: $display-9;
        font-weight: 500;
    }
    &__value {
        color: $co-grey-1;
        font-size: $display-2;
        font-weight: 500;

        #{$this}--main & {
            color: $co-secondary;
        }
    }
    &__unit {
        color: $co-grey-1;
        font-size: $display-2;
        font-weight: 500;

        #{$this}--main & {
            color: $co-secondary;
        }
    }
    &__status {
        display: flex;
        align-items: center;
        gap: 0.3rem;
        font-size: $display-9;
        font-weight: 400;

        &--positive {
            color: $co-status-positive;
            &::after {
                display: inline-flex;
                width: 10px;
                height: 10px;
                content: "";
                background-image: url(#{$imagesUrl}/arrow-drop-up-ico.svg);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }
        &--negative {
            color: $co-status-negative;
            &::after {
                display: inline-flex;
                width: 10px;
                height: 10px;
                content: "";
                background-image: url(#{$imagesUrl}/arrow-drop-down-ico.svg);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }
    }

    &--detail {
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 82vh;
        gap: 0;
        padding: 0;
    }

    &--typology {
        border: none;
        box-shadow: none;
    }
}
