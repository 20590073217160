.oc-group {
    $this: &;
    
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: $gutter;

    &--half-gap {
        gap: calc($gutter / 2);
    }

    &--no-gap {
        gap: 0;
    }

    &--nowrap {
        flex-wrap: nowrap;
    }

    &--summary {
        flex-basis: 100%;
        align-items: flex-start;
    }

    &--sort {
        align-items: center;
        gap: 2px;

        .mc-icon__generic {
            display: none;

            @include breakpoint(laptop) {
                display: flex;
            }
        }
    }

    &--min-content {
        width: min-content;
    }

}