.cc-form {
    &__label {
        margin-bottom: 0.3rem;
        font-size: $display-9;
        color: $co-grey-1;
    }

    textarea {
        resize: vertical;
    }

    &__input {
        height: 30px;
        padding: 0.1rem 0.5rem;
        font-size: $display-7;
        border: solid 1px $co-border;
        @include border-radius;
        transition: all 0.2s ease-in-out;

        &:focus {
            outline: none;
            outline: solid 1px $co-primary;
        }

        &--search {
            content: "";
            padding: 0.1rem 2rem 0.1rem 0.5rem;
            background-image: url(#{$imagesUrl}/search-ico.svg);
            background-repeat: no-repeat;
            background-size: 13px;
            background-position: right 10px center;
        }

        .cc-filter__group--last & {
            width: 85px;
        }
    }

    &__select {
        width: 100%;
        padding: 0.1rem 2rem 0.1rem 0.5rem;
        border: none;
        background-color: transparent;
        content: "";
        background-image: url(#{$imagesUrl}/arrow-down-ico.svg);
        background-repeat: no-repeat;
        background-size: 11px;
        background-position: right 10px center;
        cursor: pointer;
        appearance: none;

        &:focus {
            outline: none;
        }

        &-options {
            position: absolute;
            top: 48px;
            left: -1px;
            display: flex;
            flex-direction: column;
            gap: calc($gutter / 3);
            width: 100%;
            min-width: 60px;
            border: solid 1px $co-border;
            background-color: $co-lighter;
        }

        &-item {
            padding: 2px 12px;

            &:hover {
                background-color: $co-grey-6;
            }

            &.is-active {
                display: none;
            }
        }
    }

    &__checkbox {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 10;
        cursor: pointer;

        &-label {
            position: relative;
            display: flex;
            align-items: center;
            padding-left: 1.3rem;
            cursor: pointer;
            text-transform: capitalize;

            .cc-filter__group & {
                align-items: flex-start;
            }
        }

        &-icon {
            position: absolute;
            left: 0;
            bottom: 2px;
            width: 14px;
            height: 14px;
            background-color: $co-lighter;
            border: 1px solid $co-grey-2;
            border-radius: 3px;
        }

        &:checked + .cc-form__checkbox-label .cc-form__checkbox-icon {
            background-color: $co-primary;
            border-color: $co-primary;
        }

        &:checked + .cc-form__checkbox-label .cc-form__checkbox-icon::after {
            position: absolute;
            width: 100%;
            height: 100%;
            content: "";
            background-image: url(#{$imagesUrl}/check-ico.svg);
            background-repeat: no-repeat;
            background-size: 80%;
            background-position: center;
        }
    }
}

// Tags
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}
