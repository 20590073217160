.cc-filter {
    $this: &;

    position: sticky;
    top: -20px;
    z-index: 3000;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: $gutter;
    transition: all 0.25s ease-in-out;

    &.is-overflow {
        position: fixed;
        top: 60px;
        left: $gutter;
        align-items: flex-start;
        max-width: calc(100% - 350px);
        z-index: 6000;

        @include breakpoint(laptop) {
            max-width: 90%;
        }

        .mc-button--back, 
        .mc-button--toggle-filters {
            margin-top: 4px;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem 1rem;

        &.is-overflow {
            opacity: 0;
            visibility: hidden;

            &.is-open {
                opacity: 1;
                visibility: visible;

                &:before {
                    position: absolute;
                    top: -10px;
                    left: -20px;
                    display: block;
                    width: 100vw;
                    height: 100%;
                    background: $co-grey-7;
                    content: "";
                    z-index: -1;
                    border-bottom: solid 1px $co-border;
                    @include shadow;
                }
            }
        }
    }

    &__container {
        position: relative;

        @include breakpoint(mobile) {
            width: calc(100% - $gutter);

            .cc-filter__button--date::after,
            .cc-filter__button--parameter::after {
                margin-left: auto;
            }
        }
    }

    &__button {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.5rem;
        min-height: 36px;
        padding: 8px 14px;
        background: $co-lighter;
        border: solid 1px $co-border;
        @include border-radius;
        font-size: $display-6;
        font-weight: 500;
        letter-spacing: -0.25px;
        cursor: pointer;
        transition: all 0.25s ease-in-out;

        &:hover {
            border-color: $co-primary;
            @include shadow;
        }

        &.is-open {
            height: 36px;
            border-color: $co-primary;
            border-radius: 4px 4px 0 0;
        }

        &--date {
            &::after {
                width: 10px;
                height: 10px;
                content: "";
                background-image: url(#{$imagesUrl}/arrow-down-primary-ico.svg);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }

            &.is-open {
                &::after {
                    width: 10px;
                    height: 9px;
                    content: "";
                    background-image: url(#{$imagesUrl}/close-primary-ico.svg);
                    background-repeat: no-repeat;
                    background-size: 12px;
                    background-position: center;
                }
            }
        }

        &--parameter {
            &::after {
                width: 10px;
                height: 10px;
                content: "";
                background-image: url(#{$imagesUrl}/add-primary-ico.svg);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }

            &.is-open {
                &::after {
                    width: 10px;
                    height: 9px;
                    content: "";
                    background-image: url(#{$imagesUrl}/close-primary-ico.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                }
            }
        }

        &--selected {
            background: rgba(255, 255, 255, 0.6);
            color: $co-grey-2;
            font-size: $display-10;
        }
    }

    &__dropdown {
        position: absolute;
        top: 35px;
        left: 0;
        z-index: 100;
        overflow: hidden;
        width: 100%;
        min-width: 260px;
        max-height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: calc($gutter / 2);
        padding: 1rem;
        background-color: $co-lighter;
        border: solid 1px $co-primary;
        border-radius: 0 0 4px 4px;
        @include shadow;
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        max-width: 120px;
        padding: 2px 6px;
        border-radius: 3px;
        border: solid 1px $co-grey-3;
        color: $co-grey-1;
        font-size: $display-11;
        font-weight: 400;
        text-transform: uppercase;

        border: solid 1px $co-primary;
        background: $co-lighter;
        color: $co-primary;

        svg {
            // fill: $co-primary;
            // opacity: .5;
            fill: $co-primary;
        }

        &-text {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &__label {
        color: $co-darker;
        letter-spacing: 0;
        font-weight: 400;

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        height: 100%;
        overflow: auto;

        &--last {
            overflow: unset;
        }

        &--interval {
            overflow: unset;
        }
    }

    &__group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        height: 100%;
        max-height: 100%;
        overflow: auto;

        // &--preset {
        //     // gap: .8rem;
        //     padding-right: .5rem;
        // }

        &--last {
            flex-direction: row;
        }

        &--interval {
            flex-direction: row;
            gap: 1rem;

            .cc-form__input {
                width: 100%;
            }
        }

        &-title {
            font-size: $display-9;
            font-weight: 600;
            text-transform: uppercase;
            color: $co-grey-2;
        }

        &-item {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            font-size: $display-7;

            .cc-filter__dropdown & {
                position: relative;
            }

            &--preset {
                padding: 0.25rem 0.5rem;
                cursor: pointer;

                &:hover,
                &.is-active {
                    background: $co-primary-light;
                }
            }

            &--interval {
                flex-direction: column;
                gap: 0.1rem;
                color: $co-grey-1;
            }
        }
    }

    &__tabs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 0.5rem;
        border-bottom: solid 1px $co-border;

        &-item {
            font-size: $display-9;
            font-weight: 600;
            text-transform: uppercase;
            color: $co-grey-2;
            cursor: pointer;

            &.is-open {
                color: $co-primary;
            }
        }
    }
}
