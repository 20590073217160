.is-disabled {
    opacity: 0.3;
}

.is-active {
    &.is-disabled {
        opacity: 1;
        pointer-events: none;
    }
}

.is-loading {
    &__full {
        position: fixed;
        left: 0;
        top: 51px;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        padding-bottom: 51x;
        background-color: $co-lighter;

        &--anim {
            position: relative;
            margin-bottom: 100px;
            transform: translateZ(0) scale(0.48);
            backface-visibility: hidden;
            transform-origin: 0 0;
            @include loading-anim;
        }
    }

    &__small {
        display: flex;
        justify-content: center;
        width: 48px;
        height: 48px;
        margin: 0 auto;
        overflow: hidden;
        background: none;

        &--anim {
            width: 100%;
            height: 100%;
            position: relative;
            transform: translateZ(0) scale(0.48);
            backface-visibility: hidden;
            transform-origin: 0 0;
            @include loading-anim;
        }
    }

    &__button {
        pointer-events: none;

        .is-loading__small {
            display: block;
            width: 20px;
            height: 20px;
            filter: brightness(0.5);

            &--anim {
                transform: translateZ(0) scale(0.2);
            }
        }
    }
}

.is-fullwidth {
    max-width: 100%;
}

.has-fade-in {
    animation: fadeIn 0.15s ease-out forwards;
}

.has-transition {
    transition: all .2s ease-in-out;
}

.is-rotated {
    transform: rotate(180deg);
}

.is-static-page {
    width: 100vw;
    height: 100vh;
}

// .is-filter {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     width: 100%;
// }

@keyframes fadeIn {
    0% {
        opacity: 0;
        height: 0;
    }
    100% {
        opacity: 1;
        height: auto;
    }
}