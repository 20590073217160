.cc-skeleton {

    display: flex;
    flex-direction: column;

    &__item {
        width: 100%;
        margin-top: 4px;
        border-radius: 4px;
        background: $co-grey-6;
        background: linear-gradient(110deg, $co-grey-6 8%, $co-grey-8 18%, $co-grey-6 33%);
        background-size: 200% 100%;
        animation: 2s skeleton linear infinite;

        &--ai {
          border: solid 1px $co-border;

          &-check {
            width: 12px;
            height: 12px;
            border: solid 1px $co-grey-3;
            border-radius: 50%;
          }
        }
    }

}

@keyframes skeleton {
    to {
      background-position-x: -200%;
    }
  }
