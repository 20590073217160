.oc-grid {
    $this: &;
    
    position: relative;
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-flow: row;
    grid-auto-columns: minmax(0, max-content);
    margin: 0 auto;

    &__item {
        position: relative;
        &--col-12 { grid-column: span 12; }
        &--col-11 { grid-column: span 11; }
        &--col-10 { grid-column: span 10; }
        &--col-9 { grid-column: span 9; }
        &--col-8 { grid-column: span 8; }
        &--col-7 { grid-column: span 7; }
        &--col-6 { grid-column: span 6; }
        &--col-5 { grid-column: span 5; }
        &--col-4 { grid-column: span 4; }
        &--col-3 { grid-column: span 3; }
        &--col-2 { grid-column: span 2; }
        &--col-1 { grid-column: span 1; }
    }
}

// @media (max-width: 768px) {
//     [class*=oc-grid__item--col] {
//         grid-column:span 12;
//         height: fit-content
//     }
// }

// [class*=o-grid__item--col],[class*=oc-grid__item--col] {
//     gap: 0 1rem;
// }
