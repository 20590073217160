.oc-flex {
    $this: &;
    
    position: relative;
    display: flex;
    flex-direction: row;
    gap: $gutter 0;
    flex-wrap: wrap;

    &--no-gap {
        gap: 0;
    }
    &--no-wrap {
        flex-wrap: nowrap;
    }

    &--center {
       align-items: center;
       justify-content: center; 
    }
}