.cc-nav {
    $this: &;

    z-index: 3000;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gutter;
    width: 50px;
    min-width: 50px;
    height: 100vh;
    padding-top: $gutter;
    background-color: $co-lighter;
    overflow: auto;
    transition: width .25s ease-in-out;
    @include shadow;

    @include breakpoint(tablet) {
        width: 0;
        min-width: unset;

        &.is-open-nav {
            width: 50px;
        }
    }

    &__item {

        &:hover {

            .mc-icon__generic {
                fill: $co-primary;
            }

        }
    }
}
