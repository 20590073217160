/* width */
::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 8px;
    background: $co-grey-4;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: $co-grey-3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $co-grey-2;
}
