.cc-tags {
    &__title {
        font-size: $display-9;
        font-weight: 700;
        color: $co-grey-1;
    }

    &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: calc($gutter / 2);
    }
}
