.mc-logo {
    $this: &;
    align-items: center;
    padding: 10px 4px 10px 20px;

    @include breakpoint(laptop) {
        width: unset;
        // border-right: solid 1px $co-border;
    }

    &__app {
        &--comitium {
            width: 115px;
            height: 30px;
            padding: 0;
        }
        &--analytics {
            width: 90px;
            height: 30px;
            padding: 0;
        }
        &--ai {
            width: 76px;
            height: 25px;
            padding: 0;
        }
        &--advertising {
            width: 102px;
            height: 28px;
            padding: 0;
        }
        &--social-media {
            width: 134px;
            height: 30px;
            padding: 0;
        }
        &--metrics {
            width: 95px;
            height: 30px;
            padding: 0;
        }
    }

    &__image {
        &--desktop {
            display: block;
            max-width: 140px;

            // @include breakpoint(tablet) {
            @media (max-width: 990px) {
                display: none;
            }
            // @include breakpoint(mobile) {
            @media (min-width: 990px) {
                display: block;
            }
        }
        &--tablet {
            display: none;

            // @include breakpoint(tablet) {
            @media (max-width: 990px) {
                display: block;
                min-width: 28px;
            }
            // @include breakpoint(mobile) {
            @media (min-width: 990px) {
                display: none;
            }
        }
    }
}
