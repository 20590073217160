/* ========================================================================================= */
/* COMPONENTS - LOGIN */
/* ========================================================================================= */

.cc-login {
    display: flex;
    flex-direction: column;
    gap: $gutter;
    align-items: center;
    margin: $gutter;
    &__wrapper {
        position: relative;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: $gutter;
        width: 50vw;
        min-width: 300px;
        max-width: 480px;
        padding: $gutter;
        border: solid 1px $co-border;
        background: $co-lighter;
        color: $co-grey-1;
        text-align: center;

        @include border-radius;
        @include shadow;
        @include breakpoint(mobile) {
            width: 90%;
        }

        .mc-logo__image--static {
            width: 180px;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        width: 80%;
        color: $co-grey-1;
        font-size: 12px;

        .cc-form__input {
            width: 100%;
        }
    }

    &__error {
        font-size: 12px;
        color: $co-danger;
    }

    &__message {
        font-size: 12px;
        color: $co-grey-1;
    }
}
