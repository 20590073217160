.cc-summary {
    $this: &;

    position: relative;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: $gutter;
    width: 100%;
    margin-bottom: $gutter;
    padding-bottom: calc($gutter / 2);
    overflow: auto;

    &__title {
        font-size: 18px;
        color: $co-grey-1;
        font-weight: 500;
        letter-spacing: -.5px;
    }

    &__subtitle {
        font-size: 12px;
        color: $co-grey-2;
        font-weight: 500;
    }

    &__real-time {
        width: min-content;
    }

    .oc-group {
        gap: calc($gutter / 3.25);
    }

    .cc-card--detail & {
        flex-wrap: wrap;
        margin: 0;
        padding: $gutter;
    }
}
