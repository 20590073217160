.cc-header {
    $this: &;
    
    position: sticky;
    top: 0;
    z-index: 9000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    background: $co-lighter;
    border-bottom: solid 1px $co-border;
    @include shadow;

    &__lang {
        position: relative;
        padding: 0 0 0 8px;
        border-left: solid 1px $co-border;
    }

    &__search {
        display: none;
        position: relative;
        padding: 0;
        border-left: solid 1px $co-border;

        @include breakpoint(tablet) {
            display: flex;
        }

        &--inner {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            min-width: 40px;
            border: none;
            background: transparent;

            @include breakpoint(tablet) {
                right: $gutter;
            }
        }

        &-input-wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            height: 100%;
            background: $co-lighter;

            @include breakpoint(tablet) {
                display: none;

                &.is-open {
                    display: flex;
                }
            }

            @include breakpoint(tablet) {
                position: absolute;
                top: 50px;
                width: 100%;
                height: fit-content;
                padding: $gutter;
                @include shadow;

                &::after {
                    right: 30px;
                }
            }
        }

        &-input {
            min-width: 260px;
            height: 100%;
            padding: 4px 30px 4px 10px;
            box-sizing: border-box;
            border-left: solid 1px $co-border;
            background: $co-grey-6;
            font-size: $display-8;

            &:focus {
                border: solid 1px $co-primary;
                background: $co-lighter;
            }

            @include breakpoint(tablet) {
                width: 100%;
                min-width: unset;
                height: 50px;
                border: none;
            }
        }

        &-suggest {
            position: absolute;
            top: 50px;
            right: 0;
            z-index: 3000;
            display: flex;
            flex-direction: column;
            gap: 0;
            width: 90vw;
            max-width: 480px;
            max-height: 80vh;
            overflow: auto;
            background: $co-lighter;
            @include shadow;

            @include breakpoint(tablet) {
                left: 0;
                right: 0;
                top: 50px;
                width: calc(100% - ($gutter * 2));
                max-width: unset;
                margin: 0 auto;
            }

            &-item {
                display: flex;
                flex-wrap: wrap;
                gap: calc($gutter / 2);
                padding: calc($gutter / 2);
                border-bottom: solid 1px $co-border;
                color: $co-grey-1;
                font-size: $display-9;

                &:hover {
                    background: $co-primary-light;
                }

                &:last-child {
                    border-bottom: 0;
                }

                &--no-results {
                    padding: $gutter;
                    justify-content: center;
                    &:hover {
                        background: transparent;
                    }
                }

                .cc-content-list__article {
                    flex: 1 1 80%;
                }

                .cc-content-list__picture {
                    width: 65px;
                    height: 52px;
                }
            }

            &-close {
                margin-left: auto;
                padding: calc($gutter / 3) calc($gutter / 2);
                box-sizing: content-box;
                cursor: pointer;
                transition: all .15s ease-in-out;

                &:hover {
                    fill: $co-primary;
                }
            }
        }
    }

    &__menu {

        display: none !important;

        @include breakpoint(laptop) {
            display: flex !important;
        }

        .mc-button {
            width: 50px;
            min-width: unset;
            border: none;
            border-right: solid 1px $co-border;
        }
    }

    &__dropdown {
        position: absolute;
        top: 49px;
        left: 0;
        z-index: 100;
        display: flex;
        // justify-content: center;
        justify-content: flex-start;
        width: 200px;
        height: 30px;
        opacity: 0;
        visibility: hidden;
        max-height: 50px;
        display: flex;
        flex-direction: column;
        // gap: 1rem;
        // padding: 1rem;
        // background-color: $co-lighter;
        background: #ffffffd1;
        backdrop-filter: blur(1px);
        border: solid 1px $co-border;
        border-radius: 0 0 4px;
        box-shadow: 0 1px 4px $co-grey-6;
        transition: all 0.2s ease-in-out;
        overflow: hidden;

        .is-open & {
            height: calc(100vh - 50px);
            max-height: unset;
            opacity: 1;
            visibility: visible;
            // justify-content: flex-start;
            // backdrop-filter: blur(1px);
            // background: #ffffffd1;
        }

        @include breakpoint(mobile) {
            position: fixed;
            max-height: 80vh;
        }

        &-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            min-width: 40px;
            height: 49px;
            border-right: solid 1px $co-border;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &.is-open {
                transform: scaleY(-1);
                padding-top: 4px;
            }

            &:hover {
                .mc-icon__generic {
                    fill: $co-primary;
                }
            }

            &--overflowed {
                display: none;
                padding: 0;

                @include breakpoint(laptop) {
                    z-index: 20;
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    border-left: solid 1px $co-border;
                    background: $co-lighter;
                    cursor: pointer;
                }

                &.is-open {
                    transform: none;
                }
            }
        }

        &-item {
            height: 50px;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0.5rem 1rem;
            background: $co-lighter;
            border-bottom: solid 1px $co-border;

            &:hover {
                background: $co-grey-8;
            }
        }
    }

    &__sites {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
            height: 5px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
        }

        .cc-header__item {
            min-width: fit-content;
            outline: 1px solid $co-border;

            &.is-active {
                &::after {
                    position: absolute;
                    bottom: 0;
                    content: "";
                    width: 100%;
                    border-bottom: solid 4px $co-primary;
                    z-index: 10;
                }
            }
        }

        @include breakpoint(laptop) {
            position: relative;
            flex-wrap: wrap;
            gap: 1px 0;
            justify-content: flex-start;
            overflow: hidden;
            width: calc(100% - 50px);

            .cc-header__item {
                display: none;
                width: 100%;
                background: $co-lighter;

                &.is-active {
                    display: flex;
                    order: -1;
                }
            }

            &.is-open {
                overflow: visible;

                .cc-header__item {
                    display: flex;
                }
            }
        }
    }

    &__arrow {
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $co-lighter;
        top: -9px;
        height: 0;
        left: 4rem;
        position: absolute;
        width: 0;

        @include breakpoint(mobile) {
            display: none;
        }
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        color: $co-grey-1;
    }

    &__item-wrapper {
        display: flex;
        min-width: 200px;
        justify-content: space-between;
        align-items: center;

        @include breakpoint(laptop) {
            min-width: unset;
        }
    }
}
